/* eslint-disable */

class AppConsts {
  static server = 'https://trafory.eurotorg.by';

  // static server = "https://localhost:44363";

  // static server = "https://test.trafory.com:9443";

  static isProd = true;

  static isIntroHidden = false;
}

export default AppConsts;
